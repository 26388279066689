var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "prepare-settle-container" }, [
    _c("div", [
      _c("div", { staticClass: "prepare-settle-title" }, [
        _vm._v("入驻准备资料"),
      ]),
      _c("div", { staticClass: "prepare-settle-main-container" }, [
        _vm._m(0),
        _c("div", { staticClass: "prepare-settle-item" }, [
          _c("div", { staticClass: "prepare-settle-item-title" }, [
            _vm._v("资质资料"),
          ]),
          _c(
            "div",
            { staticClass: "prepare-settle-item-content" },
            [
              _c(
                "el-tabs",
                {
                  model: {
                    value: _vm.activeName,
                    callback: function ($$v) {
                      _vm.activeName = $$v
                    },
                    expression: "activeName",
                  },
                },
                [
                  _c(
                    "el-tab-pane",
                    { attrs: { label: "初级农产品", name: "1" } },
                    [
                      _c("div", { staticClass: "prepare-settle-item-text" }, [
                        _vm._v(
                          " 1、农产品合格证/绿色食品认证/农产品检测证明：3选1 "
                        ),
                      ]),
                      _c("div", { staticClass: "prepare-settle-item-text" }, [
                        _vm._v(
                          " 2、动物检疫合格证明：经营初级肉禽农产品时，必须提供 "
                        ),
                      ]),
                    ]
                  ),
                  _c("el-tab-pane", { attrs: { label: "加工品", name: "2" } }, [
                    _c("div", { staticClass: "prepare-settle-item-text" }, [
                      _vm._v(" 1、食品经营许可证/食品生产许可证：2选1 "),
                    ]),
                  ]),
                  _c("el-tab-pane", { attrs: { label: "民宿", name: "3" } }, [
                    _c("div", { staticClass: "prepare-settle-item-text" }, [
                      _vm._v(" 1、卫生许可证：必须提供 "),
                    ]),
                    _c("div", { staticClass: "prepare-settle-item-text" }, [
                      _vm._v(" 2、特种行业许可证：必须提供 "),
                    ]),
                    _c("div", { staticClass: "prepare-settle-item-text" }, [
                      _vm._v(" 3、食品经营许可证：提供餐饮/食品时，必须提供 "),
                    ]),
                  ]),
                  _c("el-tab-pane", { attrs: { label: "农家乐", name: "4" } }, [
                    _c("div", { staticClass: "prepare-settle-item-text" }, [
                      _vm._v(" 1、卫生许可证：必须提供 "),
                    ]),
                    _c("div", { staticClass: "prepare-settle-item-text" }, [
                      _vm._v(" 2、特种行业许可证：提供住宿时，必须提供 "),
                    ]),
                    _c("div", { staticClass: "prepare-settle-item-text" }, [
                      _vm._v(" 3、食品经营许可证：提供餐饮/食品时，必须提供 "),
                    ]),
                  ]),
                  _c("el-tab-pane", { attrs: { label: "餐饮", name: "5" } }, [
                    _c("div", { staticClass: "prepare-settle-item-text" }, [
                      _vm._v(" 1、卫生许可证：必须提供 "),
                    ]),
                    _c("div", { staticClass: "prepare-settle-item-text" }, [
                      _vm._v(" 2、食品经营许可证：必须提供 "),
                    ]),
                    _c("div", { staticClass: "prepare-settle-item-text" }, [
                      _vm._v(" 3、经营人员健康证：非必须 "),
                    ]),
                  ]),
                  _c("el-tab-pane", { attrs: { label: "文创", name: "6" } }, [
                    _c("div", { staticClass: "prepare-settle-item-text" }, [
                      _vm._v(" 1、经营许可证：必须提供 "),
                    ]),
                  ]),
                  _c("el-tab-pane", { attrs: { label: "旅行社", name: "7" } }, [
                    _c("div", { staticClass: "prepare-settle-item-text" }, [
                      _vm._v(" 1、经营许可证：必须提供 "),
                    ]),
                    _c("div", { staticClass: "prepare-settle-item-text" }, [
                      _vm._v(" 2、组织机构代码证：非必须 "),
                    ]),
                    _c("div", { staticClass: "prepare-settle-item-text" }, [
                      _vm._v(" 3、旅行社责任保险：非必须 "),
                    ]),
                    _c("div", { staticClass: "prepare-settle-item-text" }, [
                      _vm._v(
                        " 4、道路运输经营许可证：有车辆经营业务时，必须提供 "
                      ),
                    ]),
                    _c("div", { staticClass: "prepare-settle-item-text" }, [
                      _vm._v(" 5、车辆保险协议书：有车辆经营业务时，必须提供 "),
                    ]),
                    _c("div", { staticClass: "prepare-settle-item-text" }, [
                      _vm._v(" 6、最新保险有效期：有车辆经营业务时，必须提供 "),
                    ]),
                  ]),
                  _c("el-tab-pane", { attrs: { label: "景点", name: "8" } }, [
                    _c("div", { staticClass: "prepare-settle-item-text" }, [
                      _vm._v(" 1、经营许可证：必须提供 "),
                    ]),
                    _c("div", { staticClass: "prepare-settle-item-text" }, [
                      _vm._v(" 2、卫生许可证：必须提供 "),
                    ]),
                    _c("div", { staticClass: "prepare-settle-item-text" }, [
                      _vm._v(" 3、质量等级评定：非必须 "),
                    ]),
                    _c("div", { staticClass: "prepare-settle-item-text" }, [
                      _vm._v(" 4、特种行业许可证：提供住宿时，必须提供 "),
                    ]),
                    _c("div", { staticClass: "prepare-settle-item-text" }, [
                      _vm._v(" 5、食品经营许可证：提供餐饮/食品时，必须提供 "),
                    ]),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
        ]),
        _vm._m(1),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "prepare-settle-item" }, [
      _c("div", { staticClass: "prepare-settle-item-title" }, [
        _vm._v("基础资料"),
      ]),
      _c(
        "div",
        { staticClass: "prepare-settle-item-content no-border-bottom" },
        [
          _c("div", { staticClass: "prepare-settle-item-text" }, [
            _vm._v("营业执照"),
          ]),
          _c("div", { staticClass: "prepare-settle-item-text" }, [
            _vm._v(" 1、需提供三证合一的营业执照原件扫描件 "),
          ]),
          _c("div", { staticClass: "prepare-settle-item-text" }, [
            _vm._v(
              " 2、证件有效期截止时间应大于15天，且露出证件四角，无遮挡或模糊，保持信息清晰可见 "
            ),
          ]),
          _c("div", { staticClass: "prepare-settle-item-text" }, [
            _vm._v(
              " 3、确保未在企业经营异常名录中且所售商品在营业执照经营范围内 "
            ),
          ]),
          _c("div", { staticClass: "prepare-settle-item-text" }, [
            _vm._v(
              " 4、新办理的营业执照，因国家市场监督管理总局信息更新有延迟，建议办理成功后至少等待7个工作日后再入驻 "
            ),
          ]),
          _c("div", { staticClass: "prepare-settle-item-text" }, [
            _vm._v(
              " 5、图片尺寸为800*800px以上，支持PNG，JPG、JPEG，大小不超过5M "
            ),
          ]),
        ]
      ),
      _c("div", { staticClass: "prepare-settle-item-content" }, [
        _c("div", { staticClass: "prepare-settle-item-text" }, [
          _vm._v("身份信息"),
        ]),
        _c("div", { staticClass: "prepare-settle-item-text" }, [
          _vm._v(" 1、中国大陆：须提供法人二代身份证的正反面照片 "),
        ]),
        _c("div", { staticClass: "prepare-settle-item-text" }, [
          _vm._v(
            " 2、证件须在有效期范围内，且露出四角，无遮挡或模糊保持信息清晰可见 "
          ),
        ]),
        _c("div", { staticClass: "prepare-settle-item-text" }, [
          _vm._v(" 3、图片尺寸为800*800PX，支持PNG，JPG、JPEG，大小不超过5M "),
        ]),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "prepare-settle-item" }, [
      _c("div", { staticClass: "prepare-settle-item-title" }, [
        _vm._v("授权证明"),
      ]),
      _c("div", { staticClass: "prepare-settle-item-content" }, [
        _c("div", { staticClass: "prepare-settle-item-text" }, [
          _vm._v(" 1、品牌授权证明：非自产自销时，必须提供 "),
        ]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }