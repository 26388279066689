var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "steps-container" },
    [
      _c("div", [
        _c("div", { staticClass: "steps-main-container" }, [
          _c("div", { staticClass: "steps-item" }, [
            _c("div", { staticClass: "steps-title" }, [_vm._v("入驻步骤说明")]),
            _c("div", { staticClass: "steps-item-title" }, [
              _vm._v("01 准备资料"),
            ]),
            _c("div", { staticClass: "steps-item-text" }, [
              _vm._v(
                "1. 点击查询入驻所需资质（不同的主营类目所需的入驻资质不同）"
              ),
            ]),
            _c("div", { staticClass: "steps-item-text" }, [
              _vm._v("2. 建议您事先准备齐全资料，一次性通过审核"),
            ]),
            _c(
              "span",
              {
                staticClass: "prepare-settle",
                on: { click: _vm.openPrepareSettle },
              },
              [_vm._v("查看入驻准备")]
            ),
          ]),
          _vm._m(0),
          _vm._m(1),
          _vm._m(2),
          _vm._m(3),
          _vm._m(4),
          _vm._m(5),
        ]),
      ]),
      _c(
        "div",
        { staticClass: "operate-container" },
        [
          _c(
            "el-button",
            {
              staticStyle: { width: "300px" },
              attrs: { type: "primary", size: "medium" },
              on: { click: _vm.toInfo },
            },
            [_vm._v("我要开店")]
          ),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: { visible: _vm.dialogVisible, width: "70%", top: "5vh" },
          on: {
            close: function ($event) {
              _vm.dialogVisible = false
            },
          },
        },
        [
          _c(
            "div",
            { staticClass: "prepare-settle-container" },
            [_c("prepareSettle")],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "circle" }, [
      _c("i", { staticClass: "el-icon-arrow-right" }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "steps-item" }, [
      _c("div", { staticClass: "steps-item-title" }, [_vm._v("02 入驻准备")]),
      _c("div", { staticClass: "steps-item-text" }, [
        _vm._v("1. 选择主营类目"),
      ]),
      _c("div", { staticClass: "steps-item-text" }, [
        _vm._v("2. 根据页面提示正确填写您的入驻信息、上传相关资质"),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "circle" }, [
      _c("i", { staticClass: "el-icon-arrow-right" }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "steps-item" }, [
      _c("div", { staticClass: "steps-item-title" }, [_vm._v("03 资质审核")]),
      _c("div", { staticClass: "steps-item-text" }, [
        _vm._v(
          "1. 审核时效：普通类目店铺审核时效1个工作日以内；特殊类目店铺审核时效3个工作日（以实际审核为准）"
        ),
      ]),
      _c("div", { staticClass: "steps-item-text" }, [
        _vm._v("2. 短信通知：无论是否审核通过，我们将通过短信通知您审核结果"),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "circle" }, [
      _c("i", { staticClass: "el-icon-arrow-right" }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "steps-item" }, [
      _c("div", { staticClass: "steps-item-title" }, [_vm._v("04 开店成功")]),
      _c("div", { staticClass: "steps-item-text" }, [
        _vm._v("1. 前往商家后台开始致富之旅"),
      ]),
      _c("div", { staticClass: "steps-item-text" }, [
        _vm._v(
          "2. 商家后台：您可以登录农博商家管理后台，也可以下载农博商家版APP管理店铺"
        ),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }