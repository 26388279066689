<template>
  <div class="prepare-settle-container">
    <div>
      <div class="prepare-settle-title">入驻准备资料</div>
      <div class="prepare-settle-main-container">
        <div class="prepare-settle-item">
          <div class="prepare-settle-item-title">基础资料</div>
          <div class="prepare-settle-item-content no-border-bottom">
              <div class="prepare-settle-item-text">营业执照</div>
              <div class="prepare-settle-item-text">
                1、需提供三证合一的营业执照原件扫描件
              </div>
              <div class="prepare-settle-item-text">
                2、证件有效期截止时间应大于15天，且露出证件四角，无遮挡或模糊，保持信息清晰可见
              </div>
              <div class="prepare-settle-item-text">
                3、确保未在企业经营异常名录中且所售商品在营业执照经营范围内
              </div>
              <div class="prepare-settle-item-text">
                4、新办理的营业执照，因国家市场监督管理总局信息更新有延迟，建议办理成功后至少等待7个工作日后再入驻
              </div>
              <div class="prepare-settle-item-text">
                5、图片尺寸为800*800px以上，支持PNG，JPG、JPEG，大小不超过5M
              </div>
          </div>
          <div class="prepare-settle-item-content">
              <div class="prepare-settle-item-text">身份信息</div>
              <div class="prepare-settle-item-text">
                1、中国大陆：须提供法人二代身份证的正反面照片
              </div>
              <div class="prepare-settle-item-text">
                2、证件须在有效期范围内，且露出四角，无遮挡或模糊保持信息清晰可见
              </div>
              <div class="prepare-settle-item-text">
                3、图片尺寸为800*800PX，支持PNG，JPG、JPEG，大小不超过5M
              </div>
          </div>
        </div>
        <div class="prepare-settle-item">
          <div class="prepare-settle-item-title">资质资料</div>
          <div class="prepare-settle-item-content">
            <el-tabs v-model="activeName">
              <el-tab-pane label="初级农产品" name="1">
                <div class="prepare-settle-item-text">
                  1、农产品合格证/绿色食品认证/农产品检测证明：3选1
                </div>
                <div class="prepare-settle-item-text">
                  2、动物检疫合格证明：经营初级肉禽农产品时，必须提供
                </div>
              </el-tab-pane>
              <el-tab-pane label="加工品" name="2">
                <div class="prepare-settle-item-text">
                  1、食品经营许可证/食品生产许可证：2选1
                </div>
              </el-tab-pane>
              <el-tab-pane label="民宿" name="3">
                <div class="prepare-settle-item-text">
                  1、卫生许可证：必须提供
                </div>
                <div class="prepare-settle-item-text">
                  2、特种行业许可证：必须提供
                </div>
                <div class="prepare-settle-item-text">
                  3、食品经营许可证：提供餐饮/食品时，必须提供
                </div>
              </el-tab-pane>
              <el-tab-pane label="农家乐" name="4">
                <div class="prepare-settle-item-text">
                  1、卫生许可证：必须提供
                </div>
                <div class="prepare-settle-item-text">
                  2、特种行业许可证：提供住宿时，必须提供
                </div>
                <div class="prepare-settle-item-text">
                  3、食品经营许可证：提供餐饮/食品时，必须提供
                </div>
              </el-tab-pane>
              <el-tab-pane label="餐饮" name="5">
                <div class="prepare-settle-item-text">
                  1、卫生许可证：必须提供
                </div>
                <div class="prepare-settle-item-text">
                  2、食品经营许可证：必须提供
                </div>
                <div class="prepare-settle-item-text">
                  3、经营人员健康证：非必须
                </div>
              </el-tab-pane>
              <el-tab-pane label="文创" name="6">
                <div class="prepare-settle-item-text">
                  1、经营许可证：必须提供
                </div>
              </el-tab-pane>
              <el-tab-pane label="旅行社" name="7">
                <div class="prepare-settle-item-text">
                  1、经营许可证：必须提供
                </div>
                <div class="prepare-settle-item-text">
                  2、组织机构代码证：非必须
                </div>
                <div class="prepare-settle-item-text">
                  3、旅行社责任保险：非必须
                </div>
                <div class="prepare-settle-item-text">
                  4、道路运输经营许可证：有车辆经营业务时，必须提供
                </div>
                <div class="prepare-settle-item-text">
                  5、车辆保险协议书：有车辆经营业务时，必须提供
                </div>
                <div class="prepare-settle-item-text">
                  6、最新保险有效期：有车辆经营业务时，必须提供
                </div>
              </el-tab-pane>
              <el-tab-pane label="景点" name="8">
                <div class="prepare-settle-item-text">
                  1、经营许可证：必须提供
                </div>
                <div class="prepare-settle-item-text">
                  2、卫生许可证：必须提供
                </div>
                <div class="prepare-settle-item-text">
                  3、质量等级评定：非必须
                </div>
                <div class="prepare-settle-item-text">
                  4、特种行业许可证：提供住宿时，必须提供
                </div>
                <div class="prepare-settle-item-text">
                  5、食品经营许可证：提供餐饮/食品时，必须提供
                </div>
              </el-tab-pane>
            </el-tabs>
          </div>
        </div>
        <div class="prepare-settle-item">
          <div class="prepare-settle-item-title">授权证明</div>
          <div class="prepare-settle-item-content">
              <div class="prepare-settle-item-text">
                1、品牌授权证明：非自产自销时，必须提供
              </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
  export default {
    data() {
      return {
        activeName: '1'
      }
    }
  }
</script>
<style lang="scss" scoped>
  .prepare-settle-container {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    .prepare-settle-title {
      font-size: 18px;
      margin-bottom: 20px;
    }
    .prepare-settle-main-container {
      display: flex;
      align-items: center;
      flex-direction: column;
      .prepare-settle-item {
        width: 100%;
        margin-bottom: 15px;
        .prepare-settle-item-content {
          border: 1px solid #ccc;
          padding: 20px;
          width: 100%;
          &.no-border-bottom {
            border-bottom: none;
          }
        }
        .prepare-settle-item-title {
          font-size: 14px;
          margin-bottom: 15px;
        }
        .prepare-settle-item-text {
          margin-bottom: 10px;
        }
        .prepare-settle {
          color: #409EFF;
          text-decoration: underline;
          cursor: pointer;
        }
      }
      .circle {
        border: 1px solid #666;
        width: 20px;
        height: 20px;
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 0 20px;
        flex-shrink: 0;
        border-radius: 20px;
      }
    }
    .operate-container {
      display: flex;
      justify-content: center;
      margin-bottom: 150px;
    }
  }
</style>